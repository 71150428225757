<template>
  <v-fade-transition style="z-index : 99999999999" >
    <v-sheet  v-if="display" color="transparent"  class="toolbar" ref="toolbar" :style="style">
      <v-sheet
          @mouseover="$emit('mouseOverToolbar' , true)"
          @mouseleave="$emit('mouseOverToolbar' , false)"
          :style="`border : 1px solid ${wsLIGHTACCENT} ; color : ${wsACCENT};` + marginTopStyle"
          class="d-flex align-center px-3 "
          style="border-radius: 4px"
          height="40"
          light
      >
        <slot></slot>
        <edit-text-element-toolbar-buttons
            v-if="textEditor && !MOBILE_VIEW"
            :quill="quill"
            :element-id="elementId"
            @expand="$emit('expand' , $event)"
            :quillReadyTrigger="quillReadyTrigger"
        />
        <template
            v-for="item in items"
        >
          <ws-tooltip
              v-if="!item.children"
              :key="item.value"
              :text="$t(item.tooltip)"
          >
            <v-btn
                @click="$emit('action' , item.value)"
                :color="item.value !== 'delete' ? wsACCENT : wsWARNING"
                class="px-0 mx-0 "
                min-width="35"
                elevation="0"
                width="35"
                text
            >
              <v-icon size="20">{{  item.icon  }}</v-icon>
            </v-btn>
          </ws-tooltip>
          <ft-select
              v-else
              @input="$emit('action' , $event)"
              @expand="$emit('expand' , $event);"
              :key="item.value + 'children'"
              :items="item.children"
              min-width="0"
              delay-close
          >
            <v-btn
                :color="wsACCENT"
                class="px-0 mx-0"
                min-width="30"
                elevation="0"
                width="30"
                height="30"
                text
            >
              <v-icon size="20" :color="wsACCENT">{{ item.icon   }} </v-icon>
            </v-btn>
          </ft-select>

        </template>


      </v-sheet>
    </v-sheet>
  </v-fade-transition>
</template>

<script>
import editTextElementToolbarButtons from "@/components/AvalonEditor/Editor/elements/editTextElementToolbarButtons";
import {mapState} from "vuex";
export default {
  name: "editElementToolbar",
  components : {
    editTextElementToolbarButtons
  },
  props : {
    display : {
      type : Boolean,
      default : false
    },
    noEdit : {
      type : Boolean,
      default : false
    },
    textEditor : {
      type : Boolean,
      default : false
    },
    elementId : {},
    element : {
      type : Object,
      default() { return {config : {}} }
    },
    quill : {},
    quillReadyTrigger : {
      type : Number
    },
    isOverlapping : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      rightOverlap : 0,
      marginTop : 0
    }
  },
  watch : {
    display() {
      this.getPosition()
    },
    scrollPosition(){
      this.checkTopPadding()
    }
  },
  computed : {
    ...mapState('avalon' , ['scrollPosition' , 'header']),

    marginTopStyle() {
      return `margin-top : ${this.marginTop}px`
    },
     style() {
      let style = '';

      if (this.MOBILE_VIEW) {
        style += `left : ${this.MOBILE_WIDTH};`
      } else {
        style += 'margin-top: -50px; z-index : 99999999;'
        if ( this.rightOverlap ) {
          style += `left : ${this.rightOverlap}px;`
        }
      }

      return style
    },

    items() {
      let items = [
        { value : 'edit' , icon : 'mdi-pencil' , tooltip : 'avalon.element.edit' },
        { value : 'duplicate' , icon : 'mdi-content-copy' , tooltip : 'avalon.element.copy' },
        { value : 'layer-' , icon : 'mdi-flip-to-back'  , tooltip : 'avalon.element.flip.back' },
        { value : 'layer+' , icon : 'mdi-flip-to-front' , tooltip : 'avalon.element.flip.front' },

      ];


      if ( this.element.type === 'text' ) {
        items.splice(1,0,{ value : 'vertical_align' , icon : 'mdi-format-vertical-align-top' , children : [
            { value : 'valign_top'   ,  icon : 'mdi-format-vertical-align-top'   },
            { value : 'valign_center' , icon : 'mdi-format-vertical-align-center'   },
            { value : 'valign_bottom'  , icon : 'mdi-format-vertical-align-bottom'   },
          ]
        })
      }


      if ( this.isOverlapping ) {
        items.push(   { value : 'move_all_below' , icon : 'mdi-elevator-down' , tooltip : 'avalon.element.overlap.move_all_below' } )
      }

      items.push(   { value : 'move_all_up' , icon : 'mdi-elevator-up' , tooltip : 'avalon.element.overlap.move_all_up' } )

      items.push(  { value : 'delete' , icon : 'mdi-delete' , tooltip : 'avalon.element.delete' } )

      if ( this.noEdit ) {
        items.splice(0,1)
      }

      return items

    }
  },
  methods : {
    checkTopPadding() {
      let element = document.getElementById('element_' + this.element.id )
      if ( element ) {
        // let height = domElement.getBoundingClientRect().height

        let top = element.getBoundingClientRect().y
        let bottom = element.getBoundingClientRect().bottom



        let scrollStart = this.EDITOR_PREHEADER_HEIGHT + 58

        if ( this.header.config.overlap && this.header.config.sticky && this.isFirst   ) {
          scrollStart -= this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']*2
        }
        else if ( this.header.config.overlap && this.isFirst   ) {
          scrollStart -= this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']
        }

        if ( this.header.config.sticky ) {
          scrollStart += this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']
        }

        if (top <  scrollStart && bottom > scrollStart  ) {
          this.marginTop = -top + scrollStart
        } else {
          this.marginTop = 0
        }


      }


    },
    getPosition() {
      setTimeout(()=> {
        if (  this.$refs.toolbar ) {
          let rect =  this.$refs.toolbar.$el.getBoundingClientRect()
          if ( (this.WINDOW_WIDTH - rect.right) < 0 ) {
            this.rightOverlap = this.WINDOW_WIDTH - rect.right
          } else {
            this.rightOverlap = 0
          }
        }
      },50)

    }
  },
  mounted() {
    this.checkTopPadding()
    this.getPosition()
  }
}
</script>

<style scoped>
.toolbar {
  position: absolute !important;
  transform:translateZ(1px); /* this will do the trick  */
  /*z-index: 400 !important;*/
}
</style>